<template>
    <div class="layout">
        <Layout>
            <Header>
                <Menu mode="horizontal" theme="dark" active-name="6" class="topMenu">
                    <div class="logoBox">
                        <img src="../assets/logo.png" alt="">
                    </div>
                    <div class="layout-nav">
                        <MenuItem name="1">
                            <Icon type="ios-navigate"></Icon>
                            首页
                        </MenuItem>
                        <MenuItem name="4">
                            <Icon type="md-people" />
                            会员管理
                        </MenuItem>
                        <MenuItem name="5">
                            <Icon type="md-people" />
                            员工管理
                        </MenuItem>
                        <MenuItem name="2">
                            <Icon type="ios-albums" />
                            菜品管理
                        </MenuItem>
                        <MenuItem name="3">
                            <Icon type="ios-analytics"></Icon>
                            餐桌管理
                        </MenuItem>
                        <MenuItem name="6">
                            <Icon type="ios-pie" />
                            订单管理
                        </MenuItem>
                        <MenuItem name="7">
                            <Icon type="md-reorder" />
                            系统管理
                        </MenuItem>
                    </div>
                    <div @click="back" class="rightName">
                        {{ userData.name }}
                        <Icon type="md-arrow-dropdown" />
                    </div>
                </Menu>
            </Header>
            <Layout>
                <Sider hide-trigger :style="{background: '#fff'}">
                    <Menu active-name="1-1" theme="light" width="auto" :open-names="['1']">
                        <Submenu name="1">
                            <template #title>
                                <Icon type="ios-navigate"></Icon>
                                订单信息
                            </template>
                            <MenuItem name="1-1">订单数据</MenuItem>
                            <MenuItem name="1-2">统计数据</MenuItem>
                        </Submenu>
                        <Submenu name="2">
                            <template #title>
                                <Icon type="ios-keypad"></Icon>
                                统计分析
                            </template>
                            <MenuItem name="2-1">Option 1</MenuItem>
                            <MenuItem name="2-2">Option 2</MenuItem>
                        </Submenu>
                        <Submenu name="3">
                            <template #title>
                                <Icon type="ios-analytics"></Icon>
                                数据汇总
                            </template>
                            <MenuItem name="3-1">Option 1</MenuItem>
                            <MenuItem name="3-2">Option 2</MenuItem>
                        </Submenu>
                    </Menu>
                </Sider>
                <Layout :style="{padding: '0 14px 14px'}">
                    <Breadcrumb :style="{margin: '14px 0'}">
                        <BreadcrumbItem>订单管理</BreadcrumbItem>
                        <BreadcrumbItem>订单统计</BreadcrumbItem>
                        <BreadcrumbItem>订单列表</BreadcrumbItem>
                    </Breadcrumb>
                    <Content :style="{padding: '14px', minHeight: '280px', background: '#fff'}">
                        <Demo />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    </div>
</template>
<script>
import Demo from '@/components/Demo'
export default {
    components: {
        Demo
    },
    data() {
        return {
            userData: {}
        }
    },
    mounted() {
        window.addEventListener('message', e => {
        if (e.data.msgType == 'userInfo') {
            this.userData = e.data.info
        }
        })
    },
    methods: {
        back() {
            window.parent.postMessage({
				type: 'LogOut'
			}, "*");
        }
    },
    beforeDestroy() {
        window.removeEventListener('message')
    },
}
</script>
<style>
.ivu-layout-header{
    padding: 0 20px !important;
}
.ivu-layout-header, .ivu-menu-dark{
    background: #ccb8c5 !important;
}
.ivu-layout-content{
    overflow: auto;
}
.ivu-layout{
    height: calc(100vh - 64px);
}
.layout{
    background: #f5f7f9;
    position: relative;
    overflow: hidden;
    height: 100vh;
}
.layout-logo{
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.topMenu{
    display: flex !important;
}
.layout-nav{
    /* margin: 0 auto; */
    margin-right: 20px;
}
.logoBox{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    width: 60px;
    height: 60px;
    overflow: hidden;
}
.logoBox img{
    width: 120px;
    margin-right: 15px;
    position: relative;
    left: -32px;
    top: 15px;
}
.rightName{
    color: #8c0776;
    margin-left: auto;
    cursor: pointer;
    font-size: 16px;
}
</style>